import { IGroupedOption } from "./../components/multiselect-dropdown/multiselect-dropdown.component";
import { SortOrder } from "app/services/helper.service";
import { EMPTY, Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import {
  Device,
  IDeviceInventoryFilters,
  ReplaceDeviceForm,
} from "../models/device.model";
import { BitParaSettingsRequest } from "../components/devices/device-settings/device-settings.component";
import { LockBeaconModel } from "app/models/lock-beacon.model";
import {
  ICompanyGroupCompany,
  IUpdateCompanyGroupBindingTimeForm,
} from "app/models/company.models";
import { CameraEvent, LockEvent } from "app/models/report.model";
import {
  AllResidentSorter,
  FlaggedResidentSorter,
  NewResidentSorter,
  ResidentCompany,
  ResidentDisplayName,
  SetResidentDevice,
  UnsetResidentDevice,
} from "app/models/resident.model";
import { NewStaff } from "../models/newstaff.model";
import {
  IAlarmReasonCategory,
  IAlarmReasonsRequest,
} from "app/models/alarm-reasons.model";
import {
  CameraGroupEditForm,
  ICameraGroup,
  IVisitConfiguration,
  NewCameraGroupForm,
  VisitOnAlertConfig,
} from "app/models/camera.model";
import {
  CareRecipientCard,
  CareRecipientStatistics,
  IAlertStatistics,
  IEmaacStatistics,
  ICareRecipientCardUserLimit,
  StatisticsFilters,
  ICareRecipientCardNote,
} from "app/models/statistics.model";
import { IResidentList } from "app/components/landing-page/new-residents/new-residents-interfaces";
import { IDeviceHistory } from "app/models/device-history.model";
import { IUpdateDeviceBindTimeForm } from "app/components/landing-page/devices/device-information/device-bind-time-manager/device-bind-time-manager.component";
import {
  IActivityLog,
  ActivityLogFilters,
  IActivityDetails,
  IScheduledVisitChangeRecord,
  Action,
} from "app/models/activity-log.model";

/**
 *  Class for every API call.
 *  Authorization headers are intercept for every request automatically in interceptor.service.ts file.
 */

@Injectable()
export class ApiService {
  constructor(private http: HttpClient, private cookies: CookieService) { }

  /**
   * LOGIN API CALLS
   */

  login(data: any): Observable<any> {
    let url = "/api/login";
    return this.http.post(url, data);
  }

  logout(): Observable<boolean> {
    this.cookies.deleteAll("/", undefined, false, "Lax");
    // Get language from the localstorage before clearing it
    let language = localStorage.getItem("language");
    localStorage.clear();
    // Set language back to local storage after everything else has been cleared
    localStorage.setItem("language", language);
    return of(true);
  }

  logoutAuthenticatedUser(landingPage = false): Observable<any> {
    let url = "/api/logoutFederation/" + landingPage;
    return this.http.get(url);
  }

  logLogoutEvent(): Observable<any> {
    const url = "/api/logLogoutEvent/Portal";
    return this.http.get(url);
  }

  isAuth(): Observable<any> {
    if (!this.cookies.get("session-token")) {
      let err = "Token not found";
      return throwError(err);
    }
    let url = "/api/isAuth";
    return this.http.get(url);
  }

  isAuthSSO(): Observable<any> {
    if (!this.cookies.get("session-token")) {
      let err = "Token not found";
      return throwError(err);
    }
    let url = "/api/isAuth/SSO";
    return this.http.get(url);
  }

  isRole(role: any): Observable<any> {
    if (!this.cookies.get("session-token")) {
      let err = "Token not found";
      return throwError(err);
    }
    let url = "/api/isRole/" + role;
    return this.http.get(url);
  }

  isSSORole(role: any): Observable<any> {
    if (!this.cookies.get("session-token")) {
      let err = "Token not found";
      return throwError(err);
    }
    let url = "/api/isSSORole/" + role;
    return this.http.get(url);
  }

  startFederationLogin(data: any): Observable<any> {
    let url = "/api/login/federation/start";
    return this.http.post(url, data);
  }

  getFederationSession(data: any): Observable<any> {
    let url = "/api/login/federation/session";
    return this.http.post(url, data);
  }
  getFederationSessionDemo(): Observable<any> {
    let url = "/api/login/federation/sessiondemo";
    return this.http.get(url);
  }

  loginToFederationCustomer(data: any): Observable<any> {
    let url = "/api/login/federation/customer";
    return this.http.post(url, data);
  }

  /**
   * DISPLAY API CALLS
   */

  getDisplays(): Observable<any> {
    let url = "/api/Display";
    return this.http.get(url);
  }

  getDisplayEvents(id: number): Observable<any> {
    let url = "/api/Display/" + id;
    return this.http.get(url);
  }

  getSingleDisplayParameters(id: number): Observable<any> {
    let url = "/api/Display/" + id + "/Parameters";
    return this.http.get(url);
  }

  deleteDisplayEvent(rowId: number): Observable<any> {
    let url = "/api/Display/" + rowId;
    return this.http.delete(url);
  }

  saveDisplayEvent(data: any, isNew: boolean): Observable<any> {
    let url = "/api/Display";
    if (isNew) {
      return this.http.put(url, data);
    } else {
      return this.http.post(url, data);
    }
  }

  saveDisplayParameters(data: any): Observable<any> {
    let url = "/api/Display/Parameters";
    return this.http.post(url, data);
  }

  /**
   * DEVICE API CALLS
   */

  getDevices(data: any): Observable<any> {
    let url = "/api/Device/List";
    return this.http.post(url, data);
  }

  // Use this for getting devices faster, but with less information
  getDevicesWithBasicInfo(): Observable<Device[]> {
    const url = "/api/Device/BasicInfoList";
    return this.http.get<Device[]>(url);
  }

  /*
  getDeviceRegistry(): Observable<any> {
    let url = 'api/DeviceRegistry';
    return this.http.get(url);
  }
  */

  getOnlineStatus(getAll: boolean): Observable<any> {
    let url = "";
    if (getAll) {
      url = "/api/Device/Status/All";
    } else {
      url = "/api/Device/Status";
    }
    return this.http.get(url);
  }

  getOnlineStatusBases(): Observable<any> {
    let url = "";
    url = "/api/Device/Status/Bases";
    return this.http.get(url);
  }

  getOnlineStatusDevices(statusType: string): Observable<any> {
    let url = "/api/Device/Status/" + statusType;
    return this.http.get(url);
  }

  getActivationTranslations(language: string): Observable<any> {
    let url = "/api/Device/Activations/" + language;
    return this.http.get(url);
  }

  getDeviceTranslations(lang: string): Observable<any> {
    let url = "/api/Device/Translations/" + lang;
    return this.http.get(url);
  }

  getDeviceInfo(devId: string): Observable<any> {
    let url = "/api/Device/" + devId;
    return this.http.get(url);
  }

  updateDevice(data: Device): Observable<any> {
    let url = "/api/Device/";
    return this.http.post(url, data);
  }

  deleteDevice(id: string): Observable<any> {
    let url = "/api/Device/" + id;
    return this.http.delete(url);
  }

  cancelDeleteDevice(id: string): Observable<any> {
    let url = "/api/Device/CancelDelete/" + id;
    return this.http.post(url, id);
  }

  forceDeleteDevice(id: string): Observable<any> {
    let url = "/api/Device/Force/" + id;
    return this.http.delete(url);
  }

  retransferSettings(id: string): Observable<any> {
    let url = "/api/Device/Retransfer/" + id;
    return this.http.get(url);
  }

  cancelSettingTransfer(id: string): Observable<any> {
    let url = "/api/Device/SettingTransfer/" + id;
    return this.http.delete(url);
  }

  checkDeviceValidity(id: string): Observable<any> {
    let url = "/api/Device/isFree/" + id;
    return this.http.get(url);
  }

  addDevice(data: Device): Observable<any> {
    let url = "/api/Device/";
    return this.http.put(url, data);
  }

  replaceDevice(data: ReplaceDeviceForm): Observable<any> {
    let url = "/api/Device/ReplaceDevice";
    return this.http.post(url, data);
  }

  cancelReplace(id: string): Observable<any> {
    let url = "/api/Device/CancelReplace/" + id;
    return this.http.get(url);
  }

  saveDeviceBitPara(data: BitParaSettingsRequest): Observable<any> {
    let url = "/api/Device/SaveBitPara";
    return this.http.post(url, data);
  }

  saveAdvancedSettings(data: any): Observable<any> {
    let url = "/api/Device/SaveAdvancedSettings";
    return this.http.post(url, data);
  }

  saveDeviceNetworkSettings(data: any): Observable<any> {
    let url = "/api/Device/SaveNetworkSettings";
    return this.http.post(url, data);
  }

  getDeviceFirmwareVersions(device: string): Observable<any> {
    let url = "/api/Device/FirmwareUpdate/" + device;
    return this.http.get(url);
  }

  startFirmwareUpdate(data: any): Observable<any> {
    let url = "/api/Device/FirmwareUpdate";
    return this.http.put(url, data);
  }

  cancelFirmwareUpdate(id: string): Observable<any> {
    let url = "/api/Device/FirmwareUpdate/" + id;
    return this.http.delete(url);
  }

  getDeviceNameList(idList: string[]): Observable<any> {
    let url = "/api/Device/NameList";
    return this.http.post(url, idList);
  }

  getCompanyGroupDeviceInventory(
    data: IDeviceInventoryFilters
  ): Observable<any> {
    let url = "/api/Device/CompanyGroupInventory";
    return this.http.post(url, data);
  }

  getCompanyGroupInventorySummary(): Observable<any> {
    let url = "/api/Device/CompanyGroupInventorySummary";
    return this.http.get(url);
  }

  getCompanyInventory(customerId: string): Observable<any> {
    let url = "/api/Device/CompanyInventory/" + customerId;
    return this.http.get(url);
  }

  saveBluetoothSettings(data: any): Observable<any> {
    let url = "/api/Device/SaveBluetoothSettings";
    return this.http.post(url, data);
  }

  uploadLockCsv(data: any): Observable<any> {
    let url = "/api/LockDevice/csv";
    return this.http.post(url, data);
  }

  getDeviceTypeSubClasses(type: string): Observable<any> {
    let url = "/api/Device/SubClass/" + type;
    return this.http.get(url);
  }

  getDeviceInventoryMovementHistory(deviceId: string): Observable<any> {
    let url = "/api/Device/DeviceInventoryMovementHistory/" + deviceId;
    return this.http.get(url);
  }

  uploadDeviceInventoryCsv(data: any): Observable<any> {
    let url = "/api/Device/AddViaCsv";
    return this.http.post(url, data);
  }

  addDeviceToInventory(data: any): Observable<any> {
    let url = "/api/Device/Inventory";
    return this.http.post(url, data);
  }

  addDeviceToCustomerInventory(data: any): Observable<any> {
    let url = "/api/Device/CustomerInventory";
    return this.http.post(url, data);
  }

  updateBaseDeviceLocation(
    device: any,
    companyLocationChanged: boolean
  ): Observable<any> {
    let url = companyLocationChanged
      ? void 0 //"/api/Device/UpdateBaseDeviceCompanyLocation"
      : "/api/Device/UpdateBaseDeviceLocation";
    return this.http.post(url, device);
  }

  getDeviceBatteryStatus(deviceId: string): Observable<number> {
    let url = "/api/Device/BatteryStatus/" + deviceId;
    return this.http.get<number>(url);
  }

  updateDeviceBindTime(form: IUpdateDeviceBindTimeForm): Observable<void> {
    let url = "/api/Device/UpdateBindTime";
    return this.http.put<void>(url, form);
  }

  // DEVICE HISTORY API CALLS
  getDeviceHistory(deviceId: string): Observable<IDeviceHistory> {
    let url = "/api/DeviceHistory/AuthenticatedUser/" + deviceId;
    return this.http.get<IDeviceHistory>(url);
  }

  // CAMERA API CALLS
  getCameraGroups(): Observable<any> {
    let url = "/api/Camera/VideoServer/CameraGroups/List";
    return this.http.get(url);
  }

  addCameraGroup(data: {
    cameraGroup: NewCameraGroupForm;
    visitConfiguration: IVisitConfiguration | undefined;
  }): Observable<any> {
    let url = "/api/Camera/VideoServer/AddCameraGroup";
    return this.http.post(url, data);
  }

  updateCameraGroup(data: {
    oldCameraGroupData: ICameraGroup;
    oldVisitConfigData: VisitOnAlertConfig;
    cameraGroupToUpdate: CameraGroupEditForm;
    visitConfigToUpdate: VisitOnAlertConfig;
    alertDevices: Record<string, string>;
  }): Observable<any> {
    let url = "/api/Camera/VideoServer/EditCameraGroup";
    return this.http.post(url, data);
  }

  deleteCameraGroup(
    cameraGroupId: string,
    visitConfigId: string,
    cameraGroupName: string
  ): Observable<boolean> {
    const url = `/api/Camera/VideoServer/DeleteCameraGroup/${visitConfigId}/${cameraGroupId}/${cameraGroupName}`;
    return this.http.delete<boolean>(url);
  }

  logScheduledVisitActivity(
    data: IScheduledVisitChangeRecord & { groupName: string; id: string; action: Action }
  ): Observable<any> {
    if (data.action === "Authentication" || data.action === "Modify")
      throw new Error("Invalid action type");

    let url = "/api/Camera/LogScheduledVisitActivity";
    return this.http.post(url, data);
  }

  /**
   * LOCATION API CALLS
   */
  getLocations(): Observable<any> {
    let url = "/api/location";
    return this.http.get(url);
  }

  getLocation(id: string): Observable<any> {
    let url = "/api/Location/" + id;
    return this.http.get(url);
  }

  updateLocation(data: any): Observable<any> {
    let url = "/api/location";
    return this.http.post(url, data);
  }

  addNewLocation(data: any): Observable<any> {
    let url = "/api/location";
    return this.http.put(url, data);
  }

  confirmLocationDelete(id: string): Observable<any> {
    let url = "/api/Location/ConfirmDelete/" + id;
    return this.http.get(url);
  }

  deleteLocation(id: string): Observable<any> {
    let url = "/api/Location/" + id;
    return this.http.delete(url);
  }

  getEmaSettings(id: string): Observable<any> {
    let url = "/api/Ema/Settings/" + id;
    return this.http.get(url);
  }

  saveEmaSettings(data: any): Observable<any> {
    let url = "/api/Ema/Settings";
    return this.http.post(url, data);
  }

  getLocationStaffList(id: string): Observable<any> {
    let url = "/api/Location/PersonnelList/" + id;
    return this.http.get(url);
  }

  updateLocationStaffList(data: any): Observable<any> {
    let url = "/api/Location/PersonnelList";
    return this.http.post(url, data);
  }

  getCompanyGroupLocations(): Observable<any> {
    let url = "/api/Location/CompanyGroupLocations";
    return this.http.get(url);
  }

  /**
   * COMPANY API CALLS
   */

  getFeatures(getOnlyEnabled: boolean): Observable<any> {
    let url = getOnlyEnabled
      ? "/api/Customer/Features"
      : "/api/Customer/Features/All";
    return this.http.get(url);
  }

  submitFeatures(features: string[]): Observable<any> {
    let url = "/api/Customer/Features";
    return this.http.post(url, features);
  }

  getCustomerInformation(): Observable<any> {
    let url = "/api/Customer";
    return this.http.get(url);
  }

  submitCustomerInformation(data: any): Observable<any> {
    let url = "/api/Customer";
    return this.http.post(url, data);
  }

  enableTwoFactorAuthentication(): Observable<any> {
    let url = "/api/Customer/EnableTwoFactorAuthentication";
    return this.http.get(url);
  }

  disableTwoFactorAuthentication(): Observable<any> {
    let url = "/api/Customer/DisableTwoFactorAuthentication";
    return this.http.get(url);
  }

  confirmTwoFactorCode(code: number): Observable<any> {
    let url = "/api/Customer/ConfirmTwoFactorAuthentication/" + code;
    return this.http.get(url);
  }

  /**
   * NOTIFICATION API CALLS
   */

  getNotifications(
    lang: string,
    amount: number,
    highlights: boolean
  ): Observable<any> {
    let url = "";
    if (highlights) {
      url = "/api/Notification/Highlights/" + lang + "/" + amount;
    } else {
      url = "/api/Notification/" + lang + "/" + amount;
    }
    return this.http.get(url);
  }

  submitNotification(data: any[], edit: boolean) {
    let url = "/api/Notification";
    if (edit) {
      return this.http.post(url, data);
    } else {
      return this.http.put(url, data);
    }
  }

  deleteNotification(rowId: number): Observable<any> {
    let url = "/api/Notification/" + rowId;
    return this.http.delete(url);
  }

  /**
   * STATISTICS API CALLS
   */

  getAlertStatistics(filters: StatisticsFilters): Observable<IAlertStatistics> {
    const url = "/api/Statistics/Alerts";
    return this.http.post<IAlertStatistics>(url, filters);
  }

  getAlarmCenterStatistics(
    filters: StatisticsFilters
  ): Observable<IEmaacStatistics> {
    const url = "/api/Statistics/Emaac";
    return this.http.post<IEmaacStatistics>(url, filters);
  }

  getCareRecipientStatisticsCards(
    filters: StatisticsFilters
  ): Observable<CareRecipientStatistics[][]> {
    const url = "/api/Statistics/CareRecipients";
    return this.http.post<CareRecipientStatistics[][]>(url, filters);
  }

  checkCareRecipientStatisticsCardUserLimit(): Observable<ICareRecipientCardUserLimit> {
    const url = "/api/Statistics/CareRecipients/UserLimit";
    return this.http.get<ICareRecipientCardUserLimit>(url);
  }

  /**
   * Request for adding note to care recipient statistics card
   * @param cardId ID of card / mongo document ID
   * @param note Note data
   * @returns True if note was added, else false
   */
  addNoteToCareRecipientStatisticsCard(
    cardId: string,
    note: ICareRecipientCardNote
  ): Observable<boolean> {
    const url = "/api/Statistics/CareRecipient/" + cardId + "/AddNote";
    return this.http.put<boolean>(url, note);
  }

  /**
   * Request for adding new care recipient statistics card
   * @param config New care recipient statistics card to add
   * @returns True if add success, else false
   */
  createCareRecipientStatisticsCard(
    config: CareRecipientCard
  ): Observable<boolean> {
    const url = "/api/Statistics/CareRecipient/Add";
    return this.http.put<boolean>(url, config);
  }

  /**
   * Request for updating care recipient statistics card
   * @param config Card update data
   * @returns True if update success, else false
   */
  updateCareRecipientStatisticsCard(
    config: CareRecipientCard
  ): Observable<boolean> {
    const url = "/api/Statistics/CareRecipient/Update";
    return this.http.put<boolean>(url, config);
  }

  /**
   * Request for deleting care recipient statistics card
   * @param cardId ID of card / mongo document ID
   * @returns True if delete success, else false
   */
  deleteCareRecipientStatisticsCard(cardId: string): Observable<boolean> {
    const url = "/api/Statistics/CareRecipient/Delete/" + cardId;
    return this.http.delete<boolean>(url);
  }

  /**
   * REPORT API CALLS
   */

  getReportData(data: any, dataType: string): Observable<any> {
    let url = "";
    switch (dataType) {
      case "stats":
        url = "/api/Report/AlertStats";
        break;
      case "trend":
        url = "/api/Report/AlertTrends";
        break;
      case "ema":
        url = "/api/Report/EmaGraph";
        break;
      default:
        url = "/api/Report/AlertStats";
        break;
    }
    return this.http.post(url, data);
  }

  getReportFilteringOptions(): Observable<any> {
    let url = "/api/Report/FilteringOptions";
    return this.http.get(url);
  }

  getAlertHistory(data: any): Observable<any> {
    let url = "/api/Report/AlertHistory";
    return this.http.post(url, data);
  }

  getCameraVisitHistory(data: any): Observable<CameraEvent[]> {
    let url = "/api/Report/CameraVisitHistory";
    return this.http.post<CameraEvent[]>(url, data);
  }

  getLockingEventHistory(data: any): Observable<LockEvent[]> {
    const url = "/api/Report/LockingEventHistory";
    return this.http.post<LockEvent[]>(url, data);
  }

  getActiveAlerts(getAll: boolean = false): Observable<any> {
    let url;
    if (getAll) {
      url = "/api/Admin/OldAlerts";
    } else {
      url = "/api/Report/ActiveAlerts";
    }
    return this.http.get(url);
  }

  getReportSummary(data: any): Observable<any> {
    let url = "/api/Report/AlertSummary";
    return this.http.post(url, data);
  }

  getEmaacStatistics(data: any): Observable<any> {
    let url = "/api/Report/EmaacStatistics";
    return this.http.post(url, data);
  }

  cancelActiveAlert(taskId: string, server: string = ""): Observable<any> {
    let url;
    if (server) {
      url = "/api/Report/CancelAlert/" + taskId + "/" + server;
    } else {
      url = "/api/Report/CancelAlert/" + taskId;
    }
    return this.http.get(url);
  }

  triggerPDFGeneration(data: any): Promise<any> {
    let url = "/api/Report/Pdf/AlertReport";
    return this.http.post(url, data).toPromise();
  }

  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = Observable.create((observer: any) => {
      reader.onloadend = (e) => {
        observer.error(JSON.parse(reader.result.toString()));
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }

  downloadReport(reportId: string): Promise<any> {
    const url = "/api/report/Pdf/" + reportId;
    return this.http
      .get(url, { responseType: "blob" })
      .pipe(catchError(this.parseErrorBlob))
      .toPromise();
  }

  addNote(data: any): Observable<any> {
    let url = "/api/Ema/Alarm/Comment";
    return this.http.put(url, data);
  }

  /**
   * ACTIVITY API CALLS
   */

  loadActivityData(deviceId: string, type: string): Observable<any> {
    let url = "/api/ActivityData/" + deviceId + "/" + type;
    return this.http.get(url);
  }

  getActivitySensors(): Observable<any> {
    let url = "/api/ActivityData/Devices";
    return this.http.get(url);
  }

  /**
   * ADMIN API CALLS
   */

  getAllCustomers(): Observable<any> {
    let url = "/api/Admin/Customers";
    return this.http.get(url);
  }

  loginAsCustomer(data: any): Observable<any> {
    let url = "/api/Login/Admin";
    return this.http.post(url, data);
  }

  search(type: string, keyword: string): Observable<any> {
    let url = "/api/Admin/Search/" + type + "/" + keyword;
    return this.http.get(url);
  }

  getNetworkFailures(): Observable<any> {
    let url = "/api/Admin/NetworkFailures";
    return this.http.get(url);
  }

  setFirmWare(data: any, editMode: boolean): Observable<any> {
    let url = "/api/Software";
    if (!editMode) {
      return this.http.put(url, data);
    } else {
      return this.http.post(url, data);
    }
  }

  getFirmwares(data: any): Observable<any> {
    let url = "/api/Software/SwVersions";
    return this.http.post(url, data);
  }

  deleteFirmware(id: number): Observable<any> {
    let url = "/api/Software/" + id;
    return this.http.delete(url);
  }

  getTestResults(id: string): Observable<any> {
    let url = "/api/Admin/TestResults/" + id;
    return this.http.get(url);
  }

  getSystemUsers(): Observable<any> {
    let url = "/api/Admin/SystemUsers";
    return this.http.get(url);
  }

  updateSystemUser(data: any): Observable<any> {
    let url = "/api/Admin/SystemUsers";
    return this.http.post(url, data);
  }

  addSystemUser(data: any): Observable<any> {
    let url = "/api/Admin/SystemUsers";
    return this.http.put(url, data);
  }

  deleteSystemUer(id: string): Observable<any> {
    let url = "/api/Admin/SystemUsers/" + id;
    return this.http.delete(url);
  }

  /**
   * NFC
   */

  /**
   * Move NFC tag to the another base station
   * @param devId NFC tag's device ID
   * @param newBaseId New basestation ID for the NFC tag
   */
  moveNfcTag(devId: string, newBaseId: string): Observable<any> {
    let url = "/api/Nfc/MoveTag";
    return this.http.post(url, {
      deviceId: devId,
      newBaseStationId: newBaseId,
    });
  }

  /**
   * ANNA PERENNA
   */

  getAnnaPerennaAlert(id: string): Observable<any> {
    let url = "/api/AnnaPerenna/Alert/" + id;
    return this.http.get(url);
  }

  getMonitors(): Observable<any> {
    let url = "/api/AnnaPerenna/Monitors";
    return this.http.get(url);
  }

  getMonitorInfo(id: string): Observable<any> {
    let url = "/api/AnnaPerenna/Monitors/" + id;
    return this.http.get(url);
  }

  getMonitorStates(data: any): Observable<any> {
    let url = "/api/AnnaPerenna/Monitors/States";
    return this.http.post(url, data);
  }

  getMonitorProfiles(): Observable<any> {
    let url = "/api/AnnaPerenna/Profiles";
    return this.http.get(url);
  }

  updateProfileData(data: any): Observable<any> {
    let url = "/api/AnnaPerenna/Profiles";
    return this.http.post(url, data);
  }

  saveNewProfile(data: any): Observable<any> {
    let url = "/api/AnnaPerenna/Profiles";
    return this.http.put(url, data);
  }

  deleteMonitorProfile(id: string): Observable<any> {
    let url = "/api/AnnaPerenna/Profiles/" + id;
    return this.http.delete(url);
  }

  updateMonitorProfile(data: any): Observable<any> {
    let url = "/api/AnnaPerenna/Monitors/Profile";
    return this.http.post(url, data);
  }

  getMonitorZoneData(id: string): Observable<any> {
    let url = "/api/AnnaPerenna/Monitors/Zones/" + id;
    return this.http.get(url);
  }

  updateZone(data: any): Observable<any> {
    let url = "/api/AnnaPerenna/Monitors/Zones";
    return this.http.post(url, data);
  }

  /**
   * STAFF MANAGEMENT
   */

  getStaffList(): Observable<any> {
    let url = "/api/Personnel";
    return this.http.get(url);
  }

  getStaffInfo(id: string): Observable<any> {
    let url = "/api/Personnel/" + id;
    return this.http.get(url);
  }

  saveStaffInfo(data: any, newUuid: string): Observable<any> {
    let url = "/api/Personnel";
    let transactionId = new HttpParams().append("transactionId", newUuid);
    return this.http.post(url, data, { params: transactionId });
  }

  /**
   * @param username
   * @returns Returns false if the user name is already in use, true if user name is unique
   */
  checkUsername(username: string): Observable<any> {
    let url = "/api/Personnel/CheckUsername/" + username;
    return this.http.get(url);
  }

  addNewStaff(data: any): Observable<any> {
    let url = "/api/Personnel";
    return this.http.put(url, data);
  }

  deleteStaff(id: string): Observable<any> {
    let url = "/api/Personnel/" + id;
    return this.http.delete(url);
  }

  changePassword(data: any): Observable<any> {
    let url = "/api/Personnel/Password";
    return this.http.post(url, data);
  }

  getIdentityList(): Observable<any> {
    let url = "/api/Personnel/Identities";
    return this.http.get(url);
  }

  updateAuthenticatedUser(data: any, newUuid: string): Observable<any> {
    let url = "/api/Personnel/Identities/Link";
    let transactionId = new HttpParams().append("transactionId", newUuid);
    return this.http.post(url, data, { params: transactionId });
  }

  saveStaffLocationList(data: any, newUuid: string): Observable<any> {
    let url = "/api/Personnel/LocationList";
    let transactionId = new HttpParams().append("transactionId", newUuid);
    return this.http.post(url, data, { params: transactionId });
  }

  getStaffLocationList(staffId: string): Observable<any> {
    if (!staffId) throw new Error("Staff ID is missing");
    let url = "/api/Personnel/LocationList/" + staffId;
    return this.http.get(url);
  }

  updateStaffLockOpeningRights(
    staffId: string,
    data: any,
    newUuid: string
  ): Observable<any> {
    let url = `/api/Personnel/${staffId}/LockOpeningRights`;
    // console.log("updateStaffLockOpeningRights", newUuid);
    let transactionId = new HttpParams().append("transactionId", newUuid);
    return this.http.post(url, data, { params: transactionId });
  }

  getStaffLockOpeningRights(staffId: string): Observable<any> {
    let url = `/api/Personnel/${staffId}/LockOpeningRights`;
    return this.http.get(url);
  }

  /**
   * ALARM ROUTES
   */

  getAlarmRoutes(): Observable<any> {
    let url = "/api/AlarmRoute";
    return this.http.get(url);
  }

  getAlarmRoutesForDevice(id: string): Observable<any> {
    let url = "/api/AlarmRoute/Device/" + id;
    return this.http.get(url);
  }

  getAlarmRouteDetails(id: string): Observable<any> {
    let url = "/api/AlarmRoute/" + id;
    return this.http.get(url);
  }

  addAlarmRoute(routeData: any): Observable<any> {
    let url = "/api/AlarmRoute";
    return this.http.put(url, routeData);
  }

  updateAlarmRoute(data: any): Observable<any> {
    let url = "/api/AlarmRoute";
    return this.http.post(url, data);
  }

  deleteAlarmRoute(id: string): Observable<any> {
    let url = "/api/AlarmRoute/" + id;
    return this.http.delete(url);
  }

  updatePersonnelAlarmRoutes(
    personnelId: string,
    data: any,
    newUuid: string
  ): Observable<any> {
    let url = "/api/AlarmRoute/Recipients/" + personnelId;
    // console.log("updatePersonnelAlarmRoutes", newUuid);
    let transactionId = new HttpParams().append("transactionId", newUuid);
    return this.http.post(url, data, { params: transactionId });
  }

  updateDeviceAlarmRoutes(data: any): Observable<any> {
    let url = "/api/AlarmRoute/Devices/UpdateActivation";
    return this.http.post(url, data);
  }

  /**
   * CALL CENTER
   */

  getCallCenters(): Observable<any> {
    let url = "/api/CallCenter/";
    return this.http.get(url);
  }

  //** USER GROUPS */

  updateUserGroups(groupIds: any): Observable<any> {
    let url = "/api/AlarmRoute";
    return this.http.post(url, groupIds);
  }

  getUsersLoggedInGroups(): Observable<any> {
    let url = "/api/User";
    return this.http.get(url);
  }

  /**
   * RECEIVER TEAMS
   */

  getReceiverTeams(): Observable<any> {
    let url = "/api/AlarmRoute/UserGroups";
    return this.http.get(url);
  }

  getAllMainUserReceiverTeams(): Observable<any> {
    const url = "/api/AlarmRoute/MainUser/UserGroups";
    return this.http.get(url);
  }

  addReceiverTeam(groupData: any): Observable<any> {
    let url = "/api/AlarmRoute/UserGroup";
    return this.http.put(url, groupData);
  }

  editReceiverTeam(groupData: any): Observable<any> {
    let url = "/api/AlarmRoute/UserGroup";
    return this.http.post(url, groupData);
  }

  deleteReceiverTeam(groupId: string): Observable<any> {
    let url = "/api/AlarmRoute/UserGroup/" + groupId;
    return this.http.delete(url);
  }

  addUserToReceiverTeam(groupId: string): Observable<any> {
    let url = "/api/AlarmRoute/UserGroups/User/" + groupId;
    return this.http.put(url, "");
  }

  deleteUserFromReceiverTeam(groupId: string): Observable<any> {
    let url = "/api/AlarmRoute/UserGroups/User/" + groupId;
    return this.http.delete(url);
  }

  changeUserActiveStatus(data: any): Observable<any> {
    let url = "/api/AlarmRoute/UserGroups/User/ActiveStatus";
    return this.http.post(url, data);
  }

  updatePersonnelReceiverTeams(
    personnelId: string,
    data: any,
    newUuid: string
  ): Observable<any> {
    let url = "/api/AlarmRoute/UserGroups/User/" + personnelId;
    // console.log("updatePersonnelReceiverTeams", newUuid);
    let transactionId = new HttpParams().append("transactionId", newUuid);
    return this.http.post(url, data, { params: transactionId });
  }

  getLocationLockAccess(groupId: string): Observable<any> {
    let url = "/api/LockDevice/receiverTeam/" + groupId + "/openingRights";
    return this.http.get(url);
  }

  updateLocationLockAccess(groupId: string, data: string[]): Observable<any> {
    let url = "/api/LockDevice/receiverTeam/" + groupId + "/openingRights";
    return this.http.post(url, data);
  }

  /**
   * LOCK AND BEACON
   */

  getLockAndBeaconConfig(id: string): Observable<any> {
    let url = "/api/LockDevice/" + id;
    return this.http.get(url);
  }

  saveLockAndBeaconConfig(data: LockBeaconModel): Observable<any> {
    let url = "/api/LockDevice";
    return this.http.post(url, data);
  }

  /**
   * REGISTRY
   */

  getMobileWhitelist(): Observable<any> {
    let url = "/api/Registry/Mobile";
    return this.http.get(url);
  }

  approveWhitelistRequest(mobileId: string): Observable<any> {
    let url = "/api/Registry/Mobile/" + mobileId + "/Approve";
    return this.http.put(url, null);
  }

  denyWhitelistRequest(mobileId: string): Observable<any> {
    let url = "/api/Registry/Mobile/" + mobileId + "/Deny";
    return this.http.put(url, null);
  }

  updateMobile(mobileId: string, data: any): Observable<any> {
    let url = "/api/Registry/Mobile/" + mobileId + "/Update";
    return this.http.put(url, data);
  }

  /**
   * RESIDENTS
   */
  getResidentList(
    pageSize: number,
    pageIndex: number,
    sortKey: NewResidentSorter,
    sortBy: SortOrder,
    search: string = ""
  ): Observable<IResidentList> {
    let url = `/api/Resident/GetResidentList/${pageSize}/${pageIndex}/${sortKey}/${sortBy}`;
    let params = new HttpParams().append("search", search);
    return this.http.get<IResidentList>(url, { params });
  }

  getCompanyGroups(): Observable<any> {
    let url = "/api/Resident/GetCompanyGroups";
    return this.http.get(url);
  }

  setResidentCompanyLocation(data: ResidentCompany[]): Observable<any> {
    let url = "/api/Resident/SetResidentCompanyLocation";
    return this.http.post(url, data);
  }

  setResidentDisplayName(data: ResidentDisplayName): Observable<any> {
    let url = "/api/Resident/SetResidentDisplayName";
    return this.http.post(url, data);
  }

  getCompanyFlaggedResidents(
    pageSize: number,
    pageIndex: number,
    sortBy: SortOrder,
    sortKey: FlaggedResidentSorter
  ): Observable<any> {
    let url = `/api/Resident/GetCompanyFlaggedResidents/${sortBy}/${sortKey}/${pageSize}/${pageIndex}`;
    return this.http.get(url);
  }

  deleteResident(data: ResidentCompany): Observable<any> {
    let url =
      "/api/Resident/DeleteResident/" + data.residentId + "/" + data.companyId;
    return this.http.delete(url);
  }

  getCompanyResidents(residentActivity: number): Observable<any> {
    let url = "/api/Resident/GetCompanyResidents/" + residentActivity;
    return this.http.get(url);
  }

  setResidentDevice(data: SetResidentDevice): Observable<any> {
    let url = "/api/Resident/SetResidentDevice";
    return this.http.post(url, data);
  }

  getResidentDevices(residentId: number): Observable<any> {
    let url = "/api/Resident/GetResidentDevices/" + residentId;
    return this.http.get(url);
  }

  unsetResidentDevice(data: UnsetResidentDevice): Observable<any> {
    let url = "/api/Resident/UnsetResidentDevice";
    return this.http.post(url, data);
  }

  getCompanyResidentDevices(): Observable<any> {
    let url = "/api/Resident/GetCompanyResidentDevices/";
    return this.http.get(url);
  }

  getResidentDevicesByCompany(companyId: string): Observable<any> {
    let url = "/api/Resident/GetCompanyResidentDevices/" + companyId;
    return this.http.get(url);
  }

  confirmResidentChanges(data: ResidentCompany[]): Observable<any> {
    let url = "/api/Resident/ConfirmResidentChanges/";
    return this.http.post(url, data);
  }

  getCompanyGroupAllResidents(
    page: number,
    search: string,
    pageSize?: number,
    sortKey?: AllResidentSorter,
    sortOrder?: SortOrder
  ): Observable<any> {
    const url = "/api/Resident/GetCompanyGroupAllResidents/" + page;
    let params = new HttpParams();
    params = params.append("search", search);
    if (pageSize) params = params.append("pageSize", pageSize);
    if (sortKey) params = params.append("sortKey", sortKey);
    if (sortOrder) params = params.append("sortBy", sortOrder);
    return this.http.get(url, { params });
  }

  getCompaynGroupResidentAndDeviceStatistics(): Observable<any> {
    let url = "/api/Resident/GetCompanyGroupResidentAndDeviceStatistics";
    return this.http.get(url);
  }

  /**
   * COMPANY
   */

  getCompanyList(): Observable<any> {
    let url = "/api/Company/List";
    return this.http.get(url);
  }

  getCompany(companyId: string, serverName: string): Observable<any> {
    let url = "/api/Company/" + companyId;
    let server = new HttpParams().append("server", serverName);
    return this.http.get(url, { params: server });
  }

  getCompanySummary(companyId: string, serverName: string): Observable<any> {
    let url = `/api/Company/${serverName}/${companyId}/Summary`;
    return this.http.get(url);
  }

  addCompany(data: any): Observable<any> {
    let url = "/api/Company/Add";
    return this.http.post(url, data);
  }

  updateCompany(data: any): Observable<any> {
    let url = "/api/Company/Update";
    return this.http.post(url, data);
  }

  deleteCompany(companyId: string, serverName: string): Observable<any> {
    let url = "/api/Company/Delete/" + serverName + "/" + companyId;
    return this.http.delete(url);
  }

  /**
   * COMPANY GROUPS
   */

  getCompanyGroupAndServerLists(): Observable<any> {
    let url = "/api/Company/List/CompanyGroupAndServer";
    return this.http.get(url);
  }

  getCompaniesByCompanyGroup(): Observable<any> {
    let url = "/api/Company/List/CompaniesByCompanyGroup";
    return this.http.get(url);
  }

  addCompanyGroup(data: any): Observable<any> {
    let url = "/api/Company/AddCompanyGroup";
    return this.http.post(url, data);
  }

  updateCompanyGroup(data: any): Observable<any> {
    let url = "/api/Company/UpdateCompanyGroup";
    return this.http.post(url, data);
  }

  // Get companies that doesn't belong to any company group
  getAvailableCompanies(server: string): Observable<any> {
    let url = "/api/Company/GetAvailableCompanies/" + server;
    return this.http.get(url);
  }

  updateCompanyGroupBindingTime(
    data: IUpdateCompanyGroupBindingTimeForm
  ): Observable<void> {
    const url = "/api/Company/SaveCompanyGroupBindingTime";
    return this.http.put<void>(url, data);
  }

  addCompanyToGroup(
    companies: ICompanyGroupCompany[],
    companyGroupId: string,
    companyGroupServer: string
  ): Observable<any> {
    let url = `/api/Company/AddCompaniesToGroup/${companyGroupId}/${companyGroupServer}`;
    return this.http.post(url, companies);
  }

  removeCompanyFromGroup(server: string, companyId: string): Observable<any> {
    let url = `/api/Company/RemoveCompanyFromGroup/${server}/${companyId}`;
    return this.http.post(url, {});
  }

  checkBindingTimeVisibility(): Observable<boolean> {
    const url = "/api/Company/CheckBindingTimeVisibility";
    return this.http.get<boolean>(url);
  }

  /**
   * @param page Page number
   * @param sortOrder 1 = ascending order, -1 descending order
   * @param search string
   * @returns staff list with optional search and sort completed
   */

  getCompanyGroupNewStaff(
    staffPageIndex: number,
    itemsPerPage?: number,
    search?: string,
    sortOrder?: number
  ): Observable<any> {
    let url = "/api/Staff/GetCompanyGroupNewStaff/" + staffPageIndex;
    let params = new HttpParams();
    if (itemsPerPage) params = params.append("itemsPerPage", itemsPerPage);
    if (search) params = params.append("search", search);
    if (sortOrder) params = params.append("sortOrder", sortOrder);
    return this.http.get(url, { params: params });
  }

  getStaffCompanyLocations(identityId: string): Observable<any> {
    const url = "/api/Staff/StaffCompanyLocations";
    let params = new HttpParams().append("identityId", identityId);
    return this.http.get(url, { params: params });
  }

  setStaffCompanyLocation(data: NewStaff[]): Observable<any> {
    let url = "/api/Staff/SetStaffCompanyLocation";
    return this.http.post(url, data);
  }

  deleteStaffCompanyLocation(id: string): Observable<any> {
    let url = "/api/Staff/RemoveCompany/" + id;
    return this.http.delete(url);
  }

  updateNewStaffRole(staffRole: number, identityId: string): Observable<any> {
    let url = "/api/Staff/UpdateNewStaffRole";
    return this.http.put(url, { identityId, staffRole });
  }

  /**
   * IOTCOMMS
   */
  getIotCommsConfigurationForLocation(id: string): Observable<any> {
    let url = "/api/IotComms/Configuration/" + id;
    return this.http.get(url);
  }

  setIotCommsConfigurationForLocation(data: any): Observable<any> {
    let url = "/api/IotComms/Configuration";
    return this.http.post(url, data);
  }

  syncLocationToIotComms(locationId: string): Observable<any> {
    let url = "/api/IotComms/Provision/" + locationId;
    return this.http.get(url);
  }

  /** Alarm Reason */
  getAlarmReasons(langId: string): Observable<any> {
    let url = "/api/AlarmReason/List/" + langId;
    return this.http.get(url);
  }

  setReasonsToAlert(data: IAlarmReasonsRequest): Observable<any> {
    let url = "/api/AlarmReason/SaveToAlert";
    return this.http.post(url, data);
  }

  saveAlarmReasons(data: IAlarmReasonCategory[]): Observable<any> {
    let url = "/api/AlarmReason/Save";
    return this.http.post(url, data);
  }

  deleteAlarmReasonCategory(category: {
    dictionaryEntryKey: string;
    alarmReasonCategoryID: number;
  }): Observable<any> {
    let url = `/api/AlarmReason/Delete/Category/${category.dictionaryEntryKey}/${category.alarmReasonCategoryID}`;
    return this.http.delete(url);
  }

  deleteAlarmReason(reason: {
    dictionaryEntryKey: string;
    alarmReasonCategoryID: number;
    alarmReasonID: number;
  }): Observable<any> {
    let url = `/api/AlarmReason/Delete/Reason/${reason.dictionaryEntryKey}/${reason.alarmReasonCategoryID}/${reason.alarmReasonID}`;
    return this.http.delete(url);
  }

  createNewAlert(
    deviceId: string,
    group: number,
    node: number
  ): Observable<any> {
    let url = "/api/Alert";
    let data = {
      deviceID: deviceId,
      alarmActivationGroup: group,
      alarmActivationNode: node,
    };
    return this.http.put(url, data);
  }

  /** ACTIVITY LOG */
  getUserFilters(): Observable<IGroupedOption[]> {
    const url = "/api/ActivityLog/GetUserFilters";
    return this.http.get<IGroupedOption[]>(url);
  }

  getActivityLogs(filters: ActivityLogFilters): Observable<IActivityLog[]> {
    const url = "/api/ActivityLog";
    return this.http.post<IActivityLog[]>(url, filters).pipe(
      map((data) => {
        !data && (data = []);

        // Validate each item in the array
        data.forEach((item) => {
          const error = this.validateActivityLog(item);

          /* Uncommented log errors, as they slow down the browser console.
          if (error) {
            console.error(
              `Data does not match IActivityLog interface. Invalid property: ${error}`
            );
          }
          */
        });
        return data;
      })
    );
  }

  private validateActivityLog(log: any): string | null {
    if (typeof log.id !== "string") return "id";
    if (typeof log.timestamp !== "string") return "timestamp";
    if (typeof log.companyId !== "string") return "companyId";
    if (typeof log.staffId !== "string") return "staffId";
    if (typeof log.staffName !== "string") return "staffName";
    if (typeof log.area !== "string") return "area";
    const detailsError = this.validateActivityDetails(log.details);
    if (detailsError) return `details.${detailsError}`;
    if (typeof log.action !== "string") return "action";
    return null;
  }

  private validateActivityDetails(details: any): string | null {
    if (typeof details.translationCode !== "string") return "translationCode";
    if (typeof details.targetName !== "string") return "targetName";
    if (typeof details.targetId !== "string") return "targetId";
    return null;
  }

  //** GLOBAL LOCAL FUNCTIONS */

  getDeviceType(type: string, name: boolean = true): string {
    let devTypeList = localStorage.getItem("devType");
    if (!devTypeList && localStorage.getItem("customerInfo")) {
      this.getDeviceTranslations(localStorage.getItem("language")).subscribe(
        (res) => {
          localStorage.setItem("devType", JSON.stringify(res));
          devTypeList = res;
        }
      );
    }
    let array = JSON.parse(localStorage.getItem("devType"));
    if (array) {
      for (var i = 0; i < array.length; i++) {
        if (array[i].deviceType === type) {
          if (array[i].deviceTypeName && name) {
            return (
              array[i].deviceTypeName + " (" + array[i].deviceTypeCode + ")"
            );
          } else {
            return array[i].deviceTypeCode;
          }
        }
      }
    }
    return "";
  }

  getDeviceIconClass(devType: string) {
    //First match exact device types
    switch (devType) {
      case "10-5":
      case "10-9":
        return "fal fa-door-closed";
      case "10-2":
      case "89-1":
      case "88-1":
        return "fal fa-fire-alt";
      case "32-6":
      case "32-8":
        return "fal fa-lock-alt";
      case "10-12":
      case "09-1":
      case "10-15":
        return "fal fa-lightbulb-on";
      case "32-1":
      case "32-3":
        return "fal fa-broadcast-tower";
      case "31-8":
      case "31-7":
        return "fal fa-tv";
      case "10-14":
        return "fal fa-memory";
      case "10-3":
        return "fal fa-video";
      case "13-1":
        return "fal fa-draw-circle";
      case "18-192":
        return "fal fa-chart-network";
      default:
        break;
    }
    //Finally match only device prefixes
    switch (devType.substring(0, 2)) {
      case "04":
      case "05":
        return "fal fa-dot-circle";
      case "06":
        return "fal fa-location";
      case "E8":
        return "fab fa-bluetooth-b";
      case "34":
        return "fal fa-video";
      case "D1":
      case "E1":
        return "fal fa-circle";
      case "33":
        return "fal fa-mobile";
      case "F0":
        return "fal fa-key";
      case "31":
        return "fal fa-microchip";
      case "08":
        return "fal fa-tag";
      case "36":
        return "fal fa-phone-square";
      default:
        break;
    }
    return "";
  }
}
